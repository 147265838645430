<template>
  <v-container fluid class="pa-0 ma-0">
    <breadcrumbs v-if="breadcrumbs" />
    <div>
      <div :style="`${getViewHeightStyle(breadcrumbs ? 76 : 56)}; ${autoScroll ? 'overflow-y: auto': ''};`">
        <slot> </slot>
      </div>
      <v-fab-transition v-if="fab || isTargetScrollable || fabScrollable">
        <v-btn
          color="primary"
          dark
          bottom
          right
          fab
          fixed
          @click="toTop"
        >
          <!-- v-scroll="onScroll" -->
          <v-icon>mdi-chevron-double-up</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumbs from "@/common/components/Breadcrumbs";

export default {
  name: "ViewContainer",
  props: {
    autoScroll: {
      type: Boolean,
      default: true,
    },
    breadcrumbs: Boolean,
    scrollTarget: Object,
    fab: Boolean,
    fabClick: Function,
  },
  data() {
    return {
      fabScrollable: false,
    };
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    ...mapGetters("ui", ["getEditorItemName"]),

    isTargetScrollable() {
      return this.scrollTarget && this.scrollTarget.value > 20;
    },
  },
  methods: {
    toTop() {
      if (!this.scrollTarget) {
        this.$vuetify.goTo(0);
      } else {
        this.$vuetify.goTo(this.scrollTarget.target, {
          container: this.scrollTarget.container,
        });
      }
    },
  },
};
</script>
