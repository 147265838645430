<template>
  <v-toolbar
    v-if="!isMd"
    class="grey lighten-5"
    flat
    height="20"
    style="z-index: 9; border-bottom: 1px dashed #ccc !important;"
  >
    <v-icon small>mdi-play</v-icon>
    <v-breadcrumbs small :items="getBreadcrumbs" class="px-1">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :href="item.link"
          :disabled="item.disabled"
          class="caption text-uppercase"
        >
          {{ item.skipTranslate ? item.name : $t(item.name) }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-toolbar>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Breadcrumbs",
  computed: {
    getBreadcrumbs() {
      return this.$route.meta.breadcrumbs.map((m) => {
        if (m.dynamic) {
          let name = m.name;
          if (m.nameParam) {
            name = this.$route.params[m.nameParam].replace(/-/g, " ");
          }

          let link = m.link;
          if (m.link) {
            const linkParamsMatches = m.link.match(/:(\w+)\/?/g);
            const linkParams = linkParamsMatches
              ? linkParamsMatches.map((m) => m.replace(/:|\//g, ""))
              : [];
            for (var pi in linkParams) {
              const paramName = linkParams[pi];
              const lp = this.$route.params[paramName];
              link = link.replace(new RegExp(":" + paramName), lp);
            }
          }

          return { ...m, name, link, skipTranslate: !!name };
        }

        return m;
      });
    },
  },
  // methods: {
  //   ...mapActions("ui", ["setBreadcrumbs", "getDynamicCrumbs"]),
  // },
};
</script>
