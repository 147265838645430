<template>
  <view-container breadcrumbs scroll-fab>
    <v-card flat>
      <v-card-text class="overflow-y-auto px-0 py-0" :style="getViewHeightStyle(76)">
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import ViewContainer from "@/common/layouts/ViewContainer";

export default {
  name: "ManagerStats",
  components: {
    ViewContainer,
  },
  data() {
    return {
      search: "",
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    // this.clearSelectedStoreProduct();
    // this.loadStoreProductsByParams({
    //   storeId: this.getStoreIdParam,
    //   ids: null,
    // }).then(() => {
    //   this.loading = false;
    // });
    // this.$nextTick(() => {
    //   window.addEventListener("resize", this.onResize);
    // });

    // this.setReactivityParams({
    //   resource: "ORDERS",
    //   scope: "business",
    //   key: "businessId",
    // });
    // this.$sub("reactivity/ORDERS", (payload) => {
    //   this.loading = true;
    //   this.removeStoreProductsFromState(payload.product).then(() => (this.loading = false));
    //   console.log("reactivity/PRODUCTS:", payload);
    // });
  },
  beforeDestroy() {
    // this.$unsubAll();
    // this.clearReactivityParams();
  },
  computed: {
  },
  methods: {
  },
};
</script>
